<template>
	<v-container class="px-0 mx-0 pa-0" fluid>
		<v-row class="pa-0 ma-0">
			<v-col cols="12">
				<div class="pa-0 px-0 ma-0">
					<v-btn class="elevation-0 pl-2 pr-2" color="warning" outlined @click="generatePDF">
						<v-icon small> picture_as_pdf </v-icon>
						{{ $t("erp.lang_print") }}
					</v-btn>
					<v-btn class="mr-0" color="grey darken-2" outlined @click="setToday">
						{{ $t("generic.lang_today") }}
					</v-btn>
					<v-btn color="grey darken-2" fab icon text @click="$refs.calendar.prev()">
						<v-icon large> mdi-chevron-left </v-icon>
					</v-btn>

					<v-btn class="elevation-0 text-black border" color="primary" fab small @click="picker = true">
						{{ new Date(requested_date).getDate() }}
					</v-btn>

					<v-btn color="grey darken-2" fab icon small @click="$refs.calendar.next()">
						<v-icon large> mdi-chevron-right </v-icon>
					</v-btn>
					<strong v-if="$refs.calendar" class="font-weight-bolder">
						{{ $refs.calendar.title }}
					</strong>
					<v-spacer></v-spacer>
				</div>
			</v-col>

			<!-- V4 1339 additional filter to reservations-->
			<v-row class="pt-0 mt-0 pa-2">
				<v-col cols="12" lg="6" md="6" sm="6">
					<BaseDateInput
						hide-details
						type="date"
						dense
						outlined
						v-model="startDate"
						:label="$t('generic.lang_from')"
					/>
				</v-col>
				<v-col cols="12" lg="6" md="6" sm="6">
					<BaseDateInput
						hide-details
						type="date"
						dense
						outlined
						v-model="endDate"
						:label="$t('generic.lang_to')"
					/>
				</v-col>
				<v-col cols="12">
					<v-btn
						block
						class="mx-auto elevation-0"
						color="primary"
						@click="filterByDate"
						:disabled="!startDate || !endDate"
					>
						<v-icon>filter_list</v-icon>
						{{ $t("generic.lang_filter") }}
					</v-btn>
				</v-col>
			</v-row>
			<!-- END V4 1339 -->

			<v-col cols="12">
				<v-row class="fill-height pa-0 ma-0">
					<v-col class="pa-0 ma-0">
						<div style="height: 0 !important; visibility: hidden !important">
							<v-calendar ref="calendar" v-model="requested_date" color="primary" type="day"></v-calendar>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col class="pt-0 mt-0" cols="12">
				<v-row class="pt-0 mt-0">
					<v-col cols="12" lg="3" md="6" sm="12">
						<b-form-input
							:class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
							v-model="search"
							:data-layout="KEYBOARD.KEYSETS.NORMAL"
							:placeholder="$t('datatables.lang_search') + ' ...'"
							size="sm"
							@focus="showTouchKeyboard"
						/>
					</v-col>
					<v-col cols="12" lg="3" md="6" sm="12">
						<b-form-select
							v-model="type"
							:class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
							:label-field="$t('generic.lang_constReservationType')"
							placeholder="type"
							size="sm"
						>
							<b-form-select-option :value="-1">{{ $t("generic.lang_allTypes") }}</b-form-select-option>
							<b-form-select-option v-for="t in this.types" :key="t.uuid" :value="t.uuid"
								>{{ t.name }}
							</b-form-select-option>
						</b-form-select>
					</v-col>

					<v-col cols="12" lg="3" md="6" sm="12">
						<b-form-select
							v-model="status"
							:class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
							:placeholder="$t('generic.lang_status')"
							se
							size="sm"
						>
							<b-form-select-option :value="-1">{{ $t("generic.lang_allStatuses") }}</b-form-select-option>
							<b-form-select-option :value="1">{{ $t("generic.lang_confirmed") }}</b-form-select-option>
							<b-form-select-option :value="0">{{ $t("generic.lang_unconfirmed") }}</b-form-select-option>
						</b-form-select>
					</v-col>

					<v-col cols="12" lg="3" md="6" sm="12">
						<b-form-select
							v-model="source"
							:class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
							:placeholder="$t('generic.lang_sources')"
							se
							size="sm"
						>
							<b-form-select-option :value="-1">{{ $t("generic.lang_allSources") }}</b-form-select-option>
							<b-form-select-option value="phone">{{ $t("generic.lang_viaPhone") }}</b-form-select-option>
							<b-form-select-option value="online">{{ $t("generic.lang_online") }}</b-form-select-option>
						</b-form-select>
					</v-col>
				</v-row>
			</v-col>
			<v-col class="pa-0" cols="12">
				<v-data-table
					:headers="headers"
					:items="this.reservations"
					:loading="loadData"
					:no-data-text="$t('generic.lang_noEntriesFound')"
				>
					<!--headers-->
					<template v-slot:header.date="{ header }">
						<font-awesome-icon
							:icon="['fal', 'calendar']"
							class="text-muted"
							size="2x"
							style="font-size: 15px"
						/>
						<span class="ml-2">{{ header.text }}</span>
					</template>

					<template v-slot:header.name="{ header }">
						<font-awesome-icon :icon="['fal', 'user']" class="text-muted" size="2x" style="font-size: 15px" />
						<span class="ml-2">{{ header.text }}</span>
					</template>

					<template v-slot:header.code="{ header }">
						<font-awesome-icon
							:icon="['fal', 'hashtag']"
							class="text-muted"
							size="2x"
							style="font-size: 15px"
						/>
						<span class="ml-2">{{ header.text }}</span>
					</template>

					<template v-slot:header.person="{ header }">
						<font-awesome-icon
							:icon="['fal', 'users']"
							class="text-muted"
							size="2x"
							style="font-size: 15px"
						/>
						<span class="ml-2">{{ header.text }}</span>
					</template>

					<template v-slot:header.phone="{ header }">
						<font-awesome-icon
							:icon="['fal', 'phone']"
							class="text-muted"
							size="2x"
							style="font-size: 15px"
						/>
						<span class="ml-2">{{ header.text }}</span>
					</template>

					<template v-slot:item.date="{ item }">
						<div class="date">
							<span class="binds"></span>
							<span class="month">{{ item.time }}</span>
							<span class="day text-muted font-weight-bolder">
								<span class="mx-2"
									><font-awesome-icon
										:icon="['fal', 'users']"
										class="text-muted"
										size="2x"
										style="font-size: 16px"
									/>
									<span class="ml-2 font-size-sm">{{ item.person }}</span>
								</span>

								<span class="mx-2"
									><font-awesome-icon
										:icon="['fal', 'keynote']"
										class="text-muted"
										size="2x"
										style="font-size: 16px"
									/>
									<span class="ml-2 font-size-sm">{{ item.tables.map((elt) => elt.name).join(",") }}</span>
								</span>
							</span>
						</div>
					</template>

					<template v-slot:item.name="{ item }">
						<v-menu
							v-if="item"
							v-model="item.selectedOpen"
							:close-on-content-click="false"
							max-width="400"
							offset-x
							z-index="1"
						>
							<template v-slot:activator="{ on }">
								<div v-on="on" class="actions">
									<strong class="primary--text">{{ item.name }}</strong>
									<br />
									<font-awesome-icon
										:icon="['fal', 'phone']"
										class="text-muted"
										size="2x"
										style="font-size: 15px"
									/>
									<span class="text--black ml-1">
										{{ item.phone }}
									</span>
									<br />
									<v-chip v-if="item.confirmed == true" color="success" x-small>
										{{ $t("generic.lang_confirmed") }}
									</v-chip>

									<v-chip v-if="item.confirmed == false" color="warning" x-small>
										{{ $t("generic.lang_unconfirmed") }}
									</v-chip>
								</div>
								<div v-if="item.code" class="copy" @click="copy(item.code)">
									<font-awesome-icon
										:icon="['fal', 'copy']"
										class="text-muted"
										size="2x"
										style="font-size: 14px"
									/>
									<span class="font-weight-bold ml-1">{{ item.code }}</span>
								</div>
							</template>
							<v-card color="white" flat min-width="350px" outlined>
								<v-toolbar class="elevation-0 text--grey" color="white" dark dense>
									<v-toolbar-title class="text-muted">
										{{ item.resType_name }}
									</v-toolbar-title>
									<v-spacer></v-spacer>

									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-btn v-on="on" icon small @click="open_email(item)">
												<font-awesome-icon
													:icon="['fal', 'envelope']"
													class="text-muted"
													size="2x"
													style="font-size: 21px"
												/>
											</v-btn>
										</template>
										{{ $t("generic.lang_contactCustomer") }}
									</v-tooltip>

									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-btn v-on="on" icon small @click="close(item)">
												<font-awesome-icon
													:icon="['fal', 'window-close']"
													class="text-muted"
													size="2x"
													style="font-size: 21px"
												/>
											</v-btn>
										</template>
										{{ $t("generic.lang_close") }}
									</v-tooltip>
								</v-toolbar>

								<v-card-text>
									<div class="pb-4">
										<v-icon v-if="item.confirmed == true" class="pb-1" color="success" large> stop </v-icon>

										<v-icon v-if="item.confirmed == false" class="pb-1" color="warning" large> stop </v-icon>
										<span>
											<strong class="font-weight-bolder font-size-lg">{{ item.name }}</strong>

											<br />
											<span class="ml-8"
												><strong>{{ $t("generic.lang_source") }}:</strong> {{ item.source }}</span
											>
											<br />
											<span class="ml-8"
												><strong>{{ $t("generic.lang_from") }}:</strong>
												{{ new Date(item.date + "T" + item.time).toLocaleString() }}</span
											>
											<br />
											<span class="ml-8"
												><strong>{{ $t("generic.lang_to") }}:</strong>
												{{
													new Date(
														new Date(item.date + "T" + item.time).getTime() + parseInt(item.max_time) * 1000
													).toLocaleString()
												}}</span
											>
										</span>
									</div>

									<div class="pb-3">
										<font-awesome-icon
											:icon="['fal', 'hashtag']"
											class="text-muted"
											size="2x"
											style="font-size: 15px"
										/>
										<span class="text--black ml-5 font-weight-bold text-primary">
											{{ item.code }}
										</span>
									</div>

									<div class="pb-3">
										<font-awesome-icon
											:icon="['fal', 'users']"
											class="text-muted"
											size="2x"
											style="font-size: 15px"
										/>
										<span class="text--black ml-5">
											{{ item.person }}
										</span>
									</div>

									<div class="pb-3">
										<font-awesome-icon
											:icon="['fal', 'keynote']"
											class="text-muted"
											size="2x"
											style="font-size: 15px"
										/>
										<span class="text--black ml-5">
											{{ item.tables.map((elt) => elt.name).join(",") }}
										</span>
									</div>

									<div class="pb-3">
										<font-awesome-icon
											:icon="['fal', 'phone']"
											class="text-muted"
											size="2x"
											style="font-size: 15px"
										/>
										<span class="text--black ml-5">
											{{ item.phone }}
										</span>
									</div>

									<div class="pb-3">
										<font-awesome-icon
											:icon="['fal', 'envelope']"
											class="text-muted"
											size="2x"
											style="font-size: 15px"
										/>
										<span class="text--black ml-5">
											{{ item.email }}
										</span>
									</div>

									<div>
										<font-awesome-icon
											:icon="['fal', 'sticky-note']"
											class="text-muted"
											size="2x"
											style="font-size: 15px"
										/>
										<span class="text-black ml-5">
											{{ item.note }}
										</span>
									</div>
								</v-card-text>
							</v-card>
						</v-menu>
					</template>

					<template v-slot:item.created_at="{ item }">
						<span>
							<font-awesome-icon
								:icon="['fal', 'calendar-alt']"
								class="text-muted"
								size="2x"
								style="font-size: 16px"
							/>
							<span class="font-weight-bold ml-2">{{ item.created_at.split(" ")[0] }}</span>
						</span>
						<br />
						<span>
							<font-awesome-icon
								:icon="['fal', 'clock']"
								class="text-muted"
								size="2x"
								style="font-size: 16px"
							/>
							<span class="font-weight-bold ml-2">{{ item.created_at.split(" ")[1] }}</span>
						</span>
					</template>
					<template v-slot:item.end_at="{ item }">
						<span>
							<font-awesome-icon
								:icon="['fal', 'clock']"
								class="text-muted"
								size="2x"
								style="font-size: 16px"
							/>
							<span class="font-weight-bold ml-2">{{ item.end_at }}</span>
						</span>
					</template>

					<template v-slot:item.resType_name="{ item }">
						<strong>{{ item.resType_name }}</strong>
						<br />
						<font-awesome-icon
							v-if="item.note || item.internal_note"
							:icon="['fal', 'sticky-note']"
							class="text-muted"
							size="2x"
							style="font-size: 16px"
						/>
						<span class="ml-2">{{
							(item.note && item.note.substring(0, 150) + " ...") ||
							(item.internal_note && item.internal_note.substring(0, 150) + " ...")
						}}</span>
					</template>

					<template v-slot:item.action="{ item }">
						<font-awesome-icon
							v-if="item.confirmed == false"
							:icon="['fal', 'check-square']"
							class="text-success actions mx-2"
							size="2x"
							style="font-size: 25px"
							@click="confirmItem(item)"
						/>

						<font-awesome-icon
							:icon="['fal', 'calendar-edit']"
							class="text-primary actions mx-2"
							size="2x"
							style="font-size: 25px"
							@click="show_edit(item)"
						/>

						<font-awesome-icon
							:icon="['fal', 'calendar-times']"
							class="text-warning actions mx-2"
							size="2x"
							style="font-size: 25px"
							@click="cancel(item)"
						/>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<!-- email dialog -->

		<v-dialog v-model="email_dialog" max-width="600px" persistent>
			<v-form ref="emailForm" lazy-validation>
				<div class="card">
					<div class="card-header">
						{{ $t("generic.lang_email") }}
					</div>

					<div class="card-body">
						<v-row>
							<v-col class="pt-0 mt-0 pb-0 mb-0" cols="12">
								<v-text-field
									v-model="email_form.subject"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:rules="[(v) => !!v]"
									clearable
									dense
									:label="$t('settings.lang_mail_subject')"
									outlined
									@focus="showTouchKeyboard"
								/>
							</v-col>

							<v-col class="pt-0 mt-0" cols="12">
								<quill-editor
									v-model="email_form.message"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									:options="quillOptions"
									:rules="[(v) => !!v]"
									@focus="showTouchKeyboard"
								></quill-editor>
							</v-col>
						</v-row>
					</div>

					<div class="card-footer text-right pa-1 pr-3">
						<v-spacer />
						<v-btn class="ma-1" color="error" @click="email_dialog = false">
							{{ $t("generic.lang_cancel") }}
						</v-btn>
						<v-btn
							:disabled="email_loader"
							:loading="email_loader"
							class="ma-1"
							color="primary"
							@click="send"
						>
							{{ $t("support.lang_supportSend") }}
						</v-btn>
					</div>
				</div>
			</v-form>
		</v-dialog>

		<!-- date picker dialog -->
		<v-dialog v-model="picker" max-width="400px" scrollable>
			<v-card align="center" class="pa-0 ma-0">
				<v-card-title class="pa-0 px-0 ma-0">
					<v-spacer />
					<v-btn icon @click="picker = false">
						<v-icon color="error"> close </v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pa-0 ma-0">
					<v-date-picker v-model="requested_date" event-color="green lighten-1" width="100%"></v-date-picker>
				</v-card-text>
			</v-card>
		</v-dialog>

		<edit-reservation
			v-if="this.edit_dialog"
			v-model="edit_dialog"
			:default_data="this.selectedEvent"
			v-bind:dialog="this.edit_dialog"
			@close_edit="close_edit()"
			@getEvents="loadRes()"
		/>

		<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
			<vue-touch-keyboard
				v-if="touchKeyboard.visible"
				id="onScreenKeyboard"
				:accept="hideTouchKeyboard"
				:cancel="hideTouchKeyboard"
				:defaultKeySet="touchKeyboard.keySet"
				:input="touchKeyboard.input"
				:layout="touchKeyboard.layout"
				:options="touchKeyboard.options"
				class="internalWidthExpanded"
			/>
		</div>

		<vue-html2pdf
			ref="html2Pdf"
			:filename="this.pdfFIleName"
			:paginate-elements-by-height="1400"
			:pdf-quality="2"
			:preview-modal="true"
			:show-layout="false"
			pdf-format="a4"
			pdf-orientation="portrait"
		>
			<section id="pdfcontainer" slot="pdf-content">
				<section class="pdf-item">
					<h4
						id="pdf-title"
						style="
							text-align: center !important;
							height: 60px !important;
							color: white;
							background-color: lightgrey;
							padding-top: 15px !important;
						"
					>
						{{ this.pdfFIleName }}
					</h4>
					<v-container fluid style="padding-right: 30px !important">
						<table border="1px" style="width: 100% !important">
							<thead>
								<th style="padding: 3px !important">#code/{{ this.$t("generic.lang_date") }}</th>

								<th style="padding: 3px !important">
									{{ $t("generic.lang_name") }}
								</th>

								<th style="padding: 3px !important">
									{{ $t("generic.lang_receipttype") }}
								</th>

								<th style="padding: 3px !important">
									{{ $t("generic.lang_createdAt") }}
								</th>

								<th style="padding: 3px !important">
									{{ $t("generic.lang_table") }}
								</th>

								<th style="padding: 3px !important">
									{{ $t("generic.lang_persons") }}
								</th>
							</thead>

							<tbody>
								<tr v-for="(res, index) in this.reservations" :key="index">
									<td style="padding: 3px !important">
										<strong># {{ res["code"] }} </strong>/
										<br />
										{{ res["date"] }} {{ res["time"] }}
									</td>

									<td style="padding: 3px !important">
										{{ res["name"] }}
										<br />
										{{ res["phone"] }}
										<br />
										{{ res["email"] }}
									</td>

									<td style="padding: 3px !important">
										{{ res["resType_name"] }}
									</td>

									<td style="padding: 3px !important">
										{{ res["created_at"] }}
									</td>
									<td style="padding: 3px !important; text-align: center">
										{{ res["tables"].map((elt) => elt.name).join(",") }}
									</td>

									<td style="padding: 3px !important; text-align: center">
										{{ res["person"] }}
									</td>
								</tr>
							</tbody>
						</table>
					</v-container>
				</section>
			</section>
		</vue-html2pdf>
	</v-container>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import mixin from "@/mixins/KeyboardMixIns";

import ReservationCalendar from "@/components/reservation/reservations/ReservationCalendar";
import Calendar from "@/views/accounting/Calendar";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAt,
	faCalendar,
	faCalendarAlt,
	faCalendarEdit,
	faCalendarTimes,
	faCheck,
	faCheckSquare,
	faClipboard,
	faClock,
	faCopy,
	faEdit,
	faEnvelope,
	faHashtag,
	faInfoSquare,
	faKeynote,
	faPhone,
	faStickyNote,
	faTrash,
	faUser,
	faUsers,
	faWindowClose,
} from "@fortawesome/pro-light-svg-icons";
import EditReservation from "@/components/reservation/reservations/EditReservation";
import VueHtml2pdf from "vue-html2pdf";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

library.add(
	faUsers,
	faClipboard,
	faClock,
	faCalendarAlt,
	faKeynote,
	faAt,
	faCalendar,
	faUser,
	faPhone,
	faCopy,
	faCheckSquare,
	faTrash,
	faInfoSquare,
	faCalendarEdit,
	faCalendarTimes,
	faWindowClose,
	faEnvelope,
	faEdit,
	faStickyNote,
	faCheck,
	faHashtag
);

export default {
	name: "ReservationList",
	mixins: [mixin],
	components: {
		EditReservation,
		ReservationCalendar,
		Calendar,
		quillEditor,
		"font-awesome-icon": FontAwesomeIcon,
		"vue-html2pdf": VueHtml2pdf,
		BaseDateInput
	},
	data() {
		return {
			pdfFIleName: this.$t("generic.lang_reservation"),
			picker: false,
			requested_date: "",
			selectedEvent: null,
			quillOptions: {
				modules: {
					toolbar: [
						["bold", "italic", "underline", "strike"],
						[{ header: [1, 2, 3, 4, 5, 6, false] }],
						[{ align: [] }],
						[{ font: [] }],
					],
				},
			},
			search: "",
			sendLoader: false,
			email_form: {
				email: "",
				subject: "",
				message: "",
			},
			email_dialog: false,
			edit_dialog: false,
			email_loader: false,
			loadData: false,
			loading: false,
			reservations: [],
			tabs: false,
			selectedOpen: false,
			types: [],
			type: -1,
			source: -1,
			status: -1,
			startDate: null,
			endDate: null,
		};
	},
	computed: {
		headers: function () {
			return [
				{
					text: this.$t("generic.lang_date"),
					value: "date",
					width: "200",
				},
				{
					text: this.$t("generic.lang_name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("generic.lang_type"),
					value: "resType_name",
					sortable: false,
				},
				{
					text: this.$t("generic.lang_createdAt"),
					value: "created_at",
					width: "200",
				},
				{
					text: this.$t("generic.lang_endsAt"),
					value: "end_at",
					width: "200",
				},
				{
					text: "",
					value: "action",
					sortable: false,
					width: "200",
				},
			];
		},
	},

	mounted() {
		this.setToday();
		this.loadRes();
		this.loadTypes();
	},
	watch: {
		search(val) {
			this.loadRes();
		},
		status(val) {
			this.loadRes();
		},
		source(val) {
			this.loadRes();
		},
		type(val) {
			this.loadRes();
		},
		requested_date(val) {
			this.loadRes();
		},
	},
	methods: {
		filterByDate() {
			this.loadData = true;
			const startDateFormatted = this.formatDate(this.startDate);
			const endDateFormatted = this.formatDate(this.endDate);

			this.axios
				.post(ENDPOINTS.RESERVATION.RESERVATIONS.GET, {
					startDate: startDateFormatted,
					endDate: endDateFormatted,
				})
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						this.reservations = [];
						this.reservations = res.data.reservations;
						this.$emit("reloadStatistics");
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loadData = false;
				});
		},
		formatDate(date) {
			const d = new Date(date);
			const year = d.getFullYear();
			const month = (d.getMonth() + 1).toString().padStart(2, "0");
			const day = d.getDate().toString().padStart(2, "0");
			return `${year}-${month}-${day}`;
		},
		generatePDF() {
			this.$refs.html2Pdf.generatePdf();
		},
		close_edit() {
			this.edit_dialog = false;
			this.selectedEvent = null;
		},
		setToday() {
			let dt = new Date();
			this.requested_date = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
		},
		prev() {
			this.$refs.calendar.prev();
		},
		next() {
			this.$refs.calendar.next();
		},
		loadTypes() {
			this.loadData = true;
			this.types = [];
			this.axios
				.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.GET)
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						this.types = res.data.types;
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loadData = false;
				});
		},
		send() {
			if (!this.$refs.emailForm.validate()) return;
			this.email_loader = true;
			this.axios
				.post(ENDPOINTS.EMAILS.RESERVATIONS.CONTACTS, this.email_form)
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_success"),
							color: "success",
						});

						this.email_dialog = false;
						this.$refs.emailForm.reset();
						this.email_form.message = "";
					} else if (res.data.STATUS === "FAILED") {
						Events.$emit("showSnackbar", {
							message: res.data.msg,
							color: "error",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.email_loader = false;
				});
		},
		close(item) {
			let index = this.reservations.indexOf(item);
			item.selectedOpen = false;
			this.reservations[index] = Object.assign({}, item);
			item.selectedOpen = false;
		},
		copy(code) {
			let self = this;
			navigator.clipboard.writeText(code).then(
				function () {
					self.$bvToast.toast(code, {
						title: self.$t("reservation.lang_reservationCodeCopied"),
						variant: "info",
						solid: true,
					});
				},
				function (err) {
					this.$bvToast.toast(code, {
						title: self.$t("reservation.lang_reservationCodeNotCopied"),
						variant: "danger",
						solid: true,
					});
				}
			);
		},
		open_email(item) {
			this.email_form.email = item.email;
			this.close(item);
			this.email_dialog = true;
		},
		confirmItem(item) {
			item.confirmed = true;
			this.saveReservation(item);
		},
		cancel(item) {
			this.$swal({
				title: this.$t("reservation.lang_voidReservationTitleMSG"),
				text: this.$t("reservation.lang_voidReservationTextMSG"),
				icon: "warning",
				showCancelButton: true,
				showLoaderOnConfirm: true,
				cancelButtonText: this.$t("generic.lang_no"),
				confirmButtonText: this.$t("generic.lang_yes"),
				preConfirm: () => {
					item.canceled = item.canceled === null ? 0 : !parseInt(item.canceled);
					this.saveReservation(item);
				},
				allowOutsideClick: () => !this.$swal.isLoading(),
			});
		},
		loadRes() {
			this.loadData = true;
			this.pdfFIleName =
				this.$t("generic.lang_reservation") + " " + new Date(this.requested_date).toLocaleDateString("de-DE");
			this.axios
				.post(
					ENDPOINTS.RESERVATION.RESERVATIONS.GET +
						"?type=" +
						this.type +
						"&source=" +
						this.source +
						"&status=" +
						this.status +
						"&day=" +
						this.requested_date +
						"&search=" +
						this.search
				)
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						this.reservations = [];
						this.reservations = res.data.reservations;
						this.$emit("reloadStatistics");
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loadData = false;
				});
		},
		show_edit(item) {
			this.selectedEvent = Object.assign({}, item);
			this.edit_dialog = true;
		},
		saveReservation(item) {
			this.loading = true;
			this.loadData = true;

			this.axios
				.post(ENDPOINTS.RESERVATION.RESERVATIONS.UPDATE, {
					uuid: item.uuid,
					file: item.file,
					date: item.date,
					time: item.time,
					person: item.person,
					customer_id: item.customer_id,
					customer: item.customer,
					customer_note: item.customer_note,
					email: item.email,
					phone: item.phone,
					end_at: item.end_at,
					note: item.note,
					resType: item.resType,
					internal_note: item.internal_note,
					confirmed: item.confirmed,
					selected: item.selected,
					canceled: item.canceled,
					customer_changed: item.customer_changed,
				})
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_success"),
							color: "success",
						});
						this.loadRes();
					} else if (res.data.STATUS === "FAILED") {
						Events.$emit("showSnackbar", {
							message: res.data.msg,
							color: "error",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
					this.loadData = false;
				});
		},
	},
};
</script>

<style scoped>
.date {
	display: block;
	width: 190px;
	height: 80px;
	margin: 5px 5px 8px 5px;
	background: #fff;
	text-align: center;
	font-family: "Helvetica", sans-serif;
	position: relative;
}

.date .binds {
	position: absolute;
	height: 15px;
	width: 150px;
	background: transparent;
	border: 2px solid #999;
	border-width: 0 5px;
	top: -6px;
	left: 0;
	right: 0;
	margin: auto;
}

.date .month {
	background: #555;
	display: block;
	padding: 8px 0;
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	border-bottom: 2px solid #333;
	box-shadow: inset 0 -1px 0 0 #666;
}

.date .day {
	display: block;
	margin: 0;
	padding: 5px 0;
	height: 35px;
	box-shadow: 0 0 3px #ccc;
	position: relative;
}

.date .day .time {
	font-size: 20px;
}

.date .day::after {
	content: "";
	display: block;
	height: 100%;
	width: 96%;
	position: absolute;
	top: 3px;
	left: 2%;
	z-index: -1;
	box-shadow: 0 0 3px #868484;
}

.date .day::before {
	content: "";
	display: block;
	height: 100%;
	width: 95%;
	position: absolute;
	top: 6px;
	left: 0%;
	z-index: -1;
	box-shadow: 0 0 3px #ccc;
}

.copy,
.actions {
	cursor: pointer;
}

.v-toolbar__content {
	padding: 0 !important;
}

#pdfcontainer {
	width: 102% !important;
	margin-left: -13px !important;
	padding: 5px !important;
	padding-top: 0 !important;
}
</style>

<!-- V4-1338-->
<style>
.ql-editor {
	height: 9rem !important;
}
</style>
<!--END V4-1338-->
